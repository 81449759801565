(function($) {

    function ajaxFormSend(form, options) {

        options.clickedSubmitButton.attr('disabled', true);

        var warningBlockClass = 'sl-help-block';

        var laddaSpinner = Ladda.create(options.clickedSubmitButton[0]);
        laddaSpinner.start();

        if (options.before !== null) {
            var beforeCallbackResult = options.before(form);
            if (beforeCallbackResult === false) {
                return false;
            };
        };

        // include form trigger data to postData
        var submitBtnData = options.clickedSubmitButton.attr('name')
            ? options.clickedSubmitButton.attr('name') + '=' + options.clickedSubmitButton.val()
            : null;

        var postData = submitBtnData ? $(form).serialize() + '&' + submitBtnData : $(form).serialize();

        $.ajaxQueue({
            url: $(form).prop('action'),
            type: $(form).prop('method'),
            data: postData,
            datatype: "json",
            async: true,
            beforeSend: function()
            {
            }
        })
        .done(function(data)
        {
            $(form).find('div.form-group').removeClass('has-error');

            laddaSpinner.stop();

            if (data.status == 'error')
            {
                // laddaSpinner.stop();
            } else {
                if (! options.leaveSpinnerOpen) {
                    
                };

                if (! options.leaveSubmitButtonDisabled) {
                    options.clickedSubmitButton.attr('disabled', false);
                }

                if (options.success != null) {
                    // TODO: check if it is a function, it can be done like this:
                    // function isFunction(functionToCheck) {
                    //     var getType = {};
                    //     return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
                    // }
                    options.success(form, data);
                };

                // TODO: move redirection to server side
                if ($(form).data()['pageAfterSubmit']) {
                    document.location.href = $(form).data()['pageAfterSubmit'];
                };
            }
        })
        .fail(function(jqXHR, ajaxOptions, thrownError)
        {
            laddaSpinner.stop();
            options.clickedSubmitButton.attr('disabled', false);

            if (options.scrollTopOnError) {
                var coords = $(form).offset().top - 40;
                $('html,body').animate({scrollTop: coords},'slow');
            };

            $.each(jqXHR.responseJSON, function(index, value)
            {
                if (options.fail != null) {
                    var done = options.fail(jqXHR, ajaxOptions, thrownError);
                    if (done) {
                        return false;
                    };
                };

                // to do: handle general validation errors
                // if (value.length != 0) {
                //     var el = $(form).find('[name="' + index + '"]');
                //     var formGroup = $(el).parents('div.form-group');
                //     var label = $(el).parents('div.form-group').find('label').text();
                //     var msgText;
                //     if (label != '') {
                //         msgText = value.toString().replace(index, label.toLowerCase());
                //     } else {
                //         msgText = value.toString();
                //     }

                //     warningMarkup = $(options.warningMarkup.replace('$$message$$', msgText));
                //     $(warningMarkup).addClass(warningBlockClass);
                //     el.after(warningMarkup);
                //     $(formGroup).addClass('has-error');
                // }

                // For now just alertify first one
                alertify.error(value);
                return;
            });
        });

        return false;
    }

    $.extend($.fn, {
        slForm: function(options) {
            var elements = this;

            if (options               == undefined) {options = {}};
            if (options.spinnerParent == undefined) {options.spinnerParent = 'div'};
            if (options.before        == undefined) {options.before        = null};
            if (options.success       == undefined) {options.success       = null};
            if (options.fail          == undefined) {options.fail          = null};
            if (options.submitButton  == undefined) {options.submitButton  = null};
            if (options.leaveSpinnerOpen  == undefined) {options.leaveSpinnerOpen  = true};
            if (options.leaveSubmitButtonDisabled  == undefined) {options.leaveSubmitButtonDisabled  = false};
            if (options.scrollTopOnError  == undefined) {options.scrollTopOnError  = false};

            $(elements).each(function(){
                var form = this;
                var submitButton = options.submitButton === null ? $(form).find('button[type="submit"]') : options.submitButton;
                submitButton.button();

                $(form).on('submit', function(evt){
                    console.log('submitted');
                    return ajaxFormSend(this, {
                        event: evt,
                        warningMarkup : '<span style="color: #e67e22; padding-left: 13px;" class="help-block m-b-none text-danger pull-left"><i class="fa fa-warning"></i> $$message$$</span>',
                        spinner : $(submitButton).closest(options.spinnerParent).find('i.fa-spinner'),
                        submitButton: submitButton,
                        clickedSubmitButton: $(document.activeElement),
                        scrollTopOnError: options.scrollTopOnError,
                        leaveSpinnerOpen: options.leaveSpinnerOpen,
                        leaveSubmitButtonDisabled: options.leaveSubmitButtonDisabled,
                        success: options.success,
                        fail: options.fail,
                        before: options.before
                    });
                });
            });
        }
    });
}(jQuery));