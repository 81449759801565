var customizeDropzone = function (url, dzSelector, options) {
    // Get the template HTML and remove it from the doumenthe template HTML and remove it from the doument
    var previewNode = document.querySelector("#template");
    previewNode.id = "";
    var previewTemplate = previewNode.parentNode.innerHTML;
    previewNode.parentNode.removeChild(previewNode);
    document.querySelector("#total-progress").style.display = "none";

    var defaultOptions = {
        url: url,
        maxFileSize: 3,
        acceptedFiles: '.pdf, .jpg, .docx, .doc',
        thumbnailWidth: 80,
        thumbnailHeight: 80,
        parallelUploads: 20,
        previewTemplate: previewTemplate,
        autoQueue: false, // Make sure the files aren't queued until manually added
        previewsContainer: "#previews", // Define the container to display the previews
        clickable: ".fileinput-button" // Define the element that should be used as click trigger to select files.
    };

    $.extend(defaultOptions, options);

    var documentDropzone = new Dropzone(document.querySelector(dzSelector), defaultOptions);


    // Update the total progress bar
    documentDropzone.on("totaluploadprogress", function (progress) {
        document.querySelector("#total-progress .progress-bar").style.width = progress + "%";
    });

    documentDropzone.on("sending", function (file, xhr, formData) {
        // Show the total progress bar when upload starts
        document.querySelector("#total-progress").style.display = "block";
        // And disable the start button
        // file.previewElement.querySelector(".start").setAttribute("disabled", "disabled");
        formData.append("_token", $('meta[name=csrf-token]').attr('content'));
    });

    documentDropzone.on("complete", function (file) {
        var previewTemplate = $(file.previewTemplate);
        previewTemplate.find('.file-controls').hide();

        previewTemplate.find('.progress').css('display', 'none');

        previewTemplate.find('.success-message').removeClass('hide');
    });

    // Hide the total progress bar when nothing's uploading anymore
    documentDropzone.on("queuecomplete", function (progress) {
        document.querySelector("#total-progress").style.display = "none";
    });

    // Setup the buttons for all transfers
    // The "add files" button doesn't need to be setup because the config
    // `clickable` has already been specified.
    // document.querySelector("#actions .start").onclick = function() {
    //     documentDropzone.enqueueFiles(documentDropzone.getFilesWithStatus(Dropzone.ADDED));
    // };

    return documentDropzone;
};