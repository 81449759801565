$(document).ready(function() {
    $('[data-modal-trigger=1]').on('click', function(e) {
        e.preventDefault();
        var target = $(this).data('modal-target');
        $('#' + target).modal('show');
    });

    $('.btn-modal-submit').on('click', function(e) {
        e.preventDefault();

        $($(this).data('target-form'))[0].submit();
    });

    $('.redirectable').css('cursor', 'pointer').on('click', function () {
        var target = $(this).attr('href');

        if (target) {
            window.location.assign(target);
        };
    });

    // datepicker
    var date = new Date();
    date.setDate(date.getDate());

    $('.datepicker').datepicker({
        format: 'dd/mm/yyyy',
        todayHighlight: true,
        autoclose: true,
        startDate: date
    });

    // make bootstrap tabs active on page onen
    (function () {
        var hash = document.location.hash;
        var prefix = "tab_";
        if (hash) {
            $('.nav-tabs a[href='+hash.replace(prefix,"")+'], [data-plugin=nav-tabs] a[href='+hash.replace(prefix,"")+']').tab('show');
            $('[data-plugin=nav-tabs] a[href='+hash.replace(prefix,"")+']').addClass('active');
        } else {
          $("[data-plugin=nav-tabs] a[href='#diary']").addClass('active');
          $("[data-plugin=nav-tabs] a[href='#new']").addClass('active');
        };

        // Change hash for page-reload
        $('.nav-tabs a, [data-plugin=nav-tabs] a').on('shown.bs.tab', function (e) {
            window.location.hash = e.target.hash.replace("#", "#" + prefix);
        });

        $('[data-plugin=nav-tabs] a').on('click', function () {
            $('[data-plugin=nav-tabs] a').removeClass('active');
            $(this).addClass('active');
        });
    })();
});
